// ** React Imports
import { useContext } from "react";

// ** Custom Components
import Paragraph from "components/typography/Paragraph";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const PresentationTile = ({ title, content }) => {
	const { isDesktop } = useContext(deviceTypeContext);

	// #####################################################

	return (
		<>
			<Paragraph size="1.5rem" textCenter bold mt={!isDesktop ? 6 : 0}>
				{title}
			</Paragraph>
			<Paragraph fullContent size="1.15rem">
				{content}
			</Paragraph>
		</>
	);
};

// #####################################################

export default PresentationTile;

// #####################################################
